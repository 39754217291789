import React, { useEffect, useRef, useState } from "react";
import {
  providerHandler,
  get_address,
  balanceOf,
  unStackednftCount,
  getNFTid,
  getStakedUserTokens,
  getReward,
  claimRewards,
  stakeTokensCheck,
  unStakeTokens,
  lockTokens,
  isApprovedForAll,
  setApprovalForAll,
  isBirthdayStart,
  rewardFromMint,
  mintRewardClaimed,
  isLocked
} from "../../contract/contract-utils";
import "./style.css";

import { Redirect } from "react-router-dom";

import Card from "../../components/card";

import Sound from 'react-sound'

import tune from "../../assets/Sonic-the-Hedgehog-2-Theme.mp3"

export default function Dashboard() {
  const checkRef = useRef(null);
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState(0);
  const [unClaimedBalance, SetUnClaimedBalance] = useState(0);
  const [numberOfNFT, setNumberOfNFT] = useState(0);
  const [unstakedNFTId, setUnstakedNFTId] = useState([]);
  const [stakedNftId, setStakedNftId] = useState([]);
  const [loader, setLoader] = useState(true);
  const [stakeName, setStaked] = useState("staked");
  const [stakedActive, setStakedActive] = useState(true);
  const [lockCount, setLockCount] = useState(0);
  const [listOfIds, setListOfIds] = useState([]);
  const [isCheckboxActive, setIsCheckboxActive] = useState(false);
  const [isApproved, setIsApproved] = useState(true);
  const [isSlectAllActive, setSelectAllActive] = useState(false);
  const [activeButtons, setActiveButtons] = useState(false);
  const [approveCheck, setApproveCheck] = useState(false);
  const [isLockButton, setIsLockButton] = useState(false);
  const [isStakeButton, setIsStakeButton] = useState(false);
  const [isStakeLock, setIsStakeLock] = useState(false);
  const [isStake, setIsStake] = useState(false);
  const [allSlected, setAllSelected] = useState(false);
  const [isBirthday, setIsBirthDay] = useState(false);
  const [showBirthDayBonusButton, setShowBirthDayBonusButton] = useState(false);
  const [toggle, setToggle] = useState(false);

  const enums = {
    STAKE_LOCK_SELECTED: "STAKE + LOCK SELECTED",
    STAKE_SELECTED: "STAKE SELECTED",
    STAKE_LOCK_MULTIPLE: "STAKE + LOCK MULTIPLE",
    STAKE_MULTIPLE: "STAKE MULTIPLE",
    UNSTAKE_MULTIPLE: "UNSTAKE MULTIPLE",
    LOCK_MULTIPLE: "LOCK MULTIPLE",
    SELECT_MULTIPLE: "SELECT MULTIPLE",
    UNSTAKE_SELECTED: "UNSTAKE SELECTED",
    LOCK_SELECTED: "LOCK SELECTED",
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "Reload";
    <Redirect to="/" />;
  };

  const resetAll = () => {
    checkRef.current.checked = false; //@TODO ceck if not selected
    setListOfIds([]);
    setLockCount(0);
    setSelectAllActive(false);
    setIsCheckboxActive(false);
    setActiveButtons(0);
    setAllSelected(false);
    setIsLockButton(false);
    setIsStakeButton(false);
    setIsStakeLock(false)
    setIsStake(false)
  };

  const handleSelectAllClick = async (event) => {
    setToggle(!toggle);
    if (event.target.checked) {
      setActiveButtons(true);
      setSelectAllActive(true);
      if (!allSlected) {
        setAllSelected(true);
      } else {
        setAllSelected(false);
      }
      if (stakedActive) {
        let listOfStakedIdsToParse = []
        for (let i=0; i<stakedNftId.length; i++){
          let response = await isLocked(stakedNftId[i])
          if(!response[0]){
            listOfStakedIdsToParse.push(stakedNftId[i])
          }
        }
        setListOfIds(listOfStakedIdsToParse);
        setLockCount(listOfStakedIdsToParse.length);
      } else {
        setListOfIds(unstakedNFTId);
        setLockCount(unstakedNFTId.length);
      }
    } else {
      setActiveButtons(false);
      setSelectAllActive(false);
      setLockCount(0);
      setListOfIds([]);
    }
  };

  const getNFTids = async () => {
    let listofNFT = [];
    for (let i = 0; i < numberOfNFT; i++) {
      let currentNFTId = await getNFTid(i);
      listofNFT.push(currentNFTId);
    }
    return listofNFT;
  };

  const getUnClaimedBalance = async () => {
    let balance = 0;
    for (let i = 0; i < stakedNftId.length; i++) {
      let currentNFTBalance = await getReward(stakedNftId[i]);
      balance += currentNFTBalance;
    }
    return balance;
  };

  const claimAllRewards = async () => {
    claimRewards(stakedNftId, setLoader);
  };

  useEffect(() => {
    providerHandler().then({});
    get_address().then((e) => {
      let strFirstThree = e.substring(0, 5);
      let strLastThree = e.substr(e.length - 5);
      const addr = `${strFirstThree}.............${strLastThree}`;
      setAddress(addr);
    });
    balanceOf().then((e) => {
      setBalance(e);
    });
    unStackednftCount().then((e) => {
      setNumberOfNFT(e);
    });
    getStakedUserTokens().then((e) => {
      setStakedNftId(e);
    });
    isApprovedForAll().then((e) => {
      setIsApproved(e);
      if (isApproved) {
        setLoader(false);
      }
      setApproveCheck(true);
    });
    isBirthdayStart().then((e) => {
      setIsBirthDay(e);
    });
  }, []);

  useEffect(() => {
    const response = mintRewardClaimed([...unstakedNFTId, ...stakedNftId]);
    if (response) {
      setShowBirthDayBonusButton(true);
    }
  }, [unstakedNFTId, stakedNftId]);

  useEffect(() => {
    getNFTids().then((e) => {
      setUnstakedNFTId(e);
      setLoader(false);
    });
  }, [numberOfNFT]);

  useEffect(() => {
    getUnClaimedBalance().then((e) => {
      SetUnClaimedBalance(e);
    });
  }, [stakedNftId]);

  useEffect(() => {
    if (lockCount >= 2) {
      setActiveButtons(true);
    } else {
      setActiveButtons(false);
    }
  }, [lockCount]);

  const handleBirthdayBonusClaim = () => {
    let paramIndexes = [...unstakedNFTId, ...stakedNftId]
    console.log(paramIndexes, "_______________________Param indexes")
    if(paramIndexes.length){
      const response = mintRewardClaimed(paramIndexes);
      rewardFromMint(response);
    }else {
      alert("You don't have any token in your wallet")
    }
  };

  const activeTab = () => {
    if (stakeName === "staked") {
      setStakedActive(false);
      setStaked("unstaked");
      resetAll();
    }
    if (stakeName === "unstaked") {
      setStakedActive(true);
      setStaked("staked");
      resetAll();
    }
  };

  const renderBalances = () => {
    return (
      <div className="show-balances">
        <div>Wallet balance: {balance.toFixed(2)} $DEGEN</div>
        <div>Unclaimed balance: {unClaimedBalance.toFixed(2)} $DEGEN</div>
      </div>
    );
  };

  const renderClaimAllButton = () => {
    return (
      <div className="claim-all-button" onClick={claimAllRewards}>
        <span>CLAIM ALL ({unClaimedBalance.toFixed(2)})</span>
      </div>
    );
  };

  const renderWalletButton = () => {
    return (
      <div className="wallet-button">
        <div className="wallet-button-details">
          <span>CONNECTED</span>
          <span>{address}</span>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="header-container">
        <div className="ui-logo">
          <img
            style={{ height: "92px", width: "246px" }}
            draggable={false}
            src={require("../../assets/game-logo.png")}
            alt="Logo"
          />
        </div>
        {renderBalances()}
        {renderClaimAllButton()}
        {renderWalletButton()}
      </div>
    );
  };

  const renderSwitchTabs = () => {
    return (
      <div className="switch-tabs">
        <div
          onClick={activeTab}
          className={
            stakedActive ? "switch-tabs-active" : "switch-tabs-inactive"
          }
        >
          STAKED
        </div>
        <div
          onClick={activeTab}
          className={
            !stakedActive ? "switch-tabs-active" : "switch-tabs-inactive"
          }
        >
          UNSTAKED
        </div>

        <div style={{ color: "white", fontWeight: "800" }}>
          <marquee> 🎉🎉LOCK TO EARN MORE 🎉🎉</marquee>
        </div>
        {isBirthday && (
          <div
            className="birthday-bonus"
            onClick={() => handleBirthdayBonusClaim()}
          >
            <span>Claim Birthday Bonus 🎁</span>
          </div>
        )}
      </div>
    );
  };

  const handleSelectMultiple = (buttonValue) => {
    if (isCheckboxActive) {
      setIsCheckboxActive(false);
    }
    setIsCheckboxActive(true);
    if (buttonValue === "lock-button") {
      setIsLockButton(true);
    }
    if (buttonValue === "unstake-button") {
      setIsStakeButton(true);
    }
    if (buttonValue === "stake-lock-multiple") {
      setIsStakeLock(true);
    }
    if (buttonValue === "stake-multiple") {
      setIsStake(true);
    }
  };

  const doNothing = () => {
    return
  }

  const renderLockButton = () => {
    return (
      <div className="buttons">
        <div className="select-all-button">
          {isCheckboxActive && (
            <div className="check-box">
              <input
                style={{
                  cursor: "pointer",
                }}
                type="checkbox"
                ref={checkRef}
                placeholder="Select all"
                name="Select All"
                value="Select All"
                onChange={(event) => handleSelectAllClick(event)}
              />
              <span
                style={{
                  color: "white",
                  fontSize: "18px",
                  fontWeight: "650",
                  paddingLeft: "4px",
                  paddingBottom: "4px",
                }}
              >
                Select All
              </span>
            </div>
          )}
        </div>
        {isCheckboxActive && (
          <div className="reset-button" onClick={() => resetAll()}>
            Reset
          </div>
        )}
        <div
          className={isCheckboxActive ? "checkbox-active-lock" : "lock-button"}
        >
          {stakedActive ? (
            <>
              {isCheckboxActive ? (
                <>
                  {isLockButton && (
                    <div
                      className={lockCount<2? "grey-state" :"green-state"}
                      onClick={() => {
                        lockCount >= 2 && listOfIds.length >= 2 ? lockTokens(listOfIds, setLoader) : doNothing();
                      }}
                    >
                      LOCK NOW
                    </div>
                  )}
                  {isStakeButton && (
                    <div
                      className={lockCount<2? "grey-state" :"green-state"}
                      onClick={() => {
                        lockCount >= 2 ? unStakeTokens(listOfIds, setLoader) : doNothing();
                      }}
                    >
                      UNSTAKE NOW
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div
                    className={isCheckboxActive ? "grey-state" : "lock-all"}
                    onClick={() => {
                      handleSelectMultiple("lock-button");
                    }}
                  >
                    {enums.LOCK_MULTIPLE}
                  </div>
                  <div
                    className={isCheckboxActive ? "grey-state" : "lock-all"}
                    onClick={() => {
                      handleSelectMultiple("unstake-button");
                    }}
                  >
                    {enums.UNSTAKE_MULTIPLE}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {isCheckboxActive ? (
                <>
                  {isStakeLock && (
                    <div
                      className={lockCount<2? "grey-state" :"green-state"}
                      onClick={() => {
                        lockCount >= 2 && listOfIds.length >= 2 ? stakeTokensCheck(listOfIds, true, setLoader) : doNothing();
                      }}
                    >
                      STAKE + LOCK NOW
                    </div>
                  )}
                  {isStake && (
                    <div
                      className={lockCount<2? "grey-state" :"green-state"}
                      onClick={() => {
                        lockCount >= 2 ? stakeTokensCheck(listOfIds, false, setLoader) : doNothing();
                      }}
                    >
                      STAKE NOW
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div
                    className={isCheckboxActive ? "grey-state" : "lock-all"}
                    onClick={() => {
                      handleSelectMultiple("stake-lock-multiple");
                    }}
                  >
                    {enums.STAKE_LOCK_MULTIPLE}
                  </div>
                  <div
                    className={isCheckboxActive ? "grey-state" : "lock-all"}
                    onClick={() => {
                      handleSelectMultiple("stake-multiple");
                    }}
                  >
                    {enums.STAKE_MULTIPLE}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderCardsList = () => {
    let list = stakedNftId;
    let message = "You have no staked tokens";
    if (stakeName === "unstaked") {
      list = unstakedNFTId;
      message = "You have no unstaked tokens";
    }
    return (
      <>
        {loader ? (
          <div
            className="loader"
            style={{ marginTop: "8%", marginLeft: "40vw" }}
          ></div>
        ) : (
          <>
            {list.length === 0 ? (
              <h1 style={{ color: "white", marginTop: "10%" }}>{message}</h1>
            ) : (
              <>
                <div className="cards-list">
                  {list.map((id) => {
                    return (
                      <Card
                        loader={loader}
                        stakedNftId={stakedNftId}
                        toggle={toggle}
                        setLoader={setLoader}
                        allSlected={allSlected}
                        setAllSelected={setAllSelected}
                        id={id}
                        stakedActive={stakedActive}
                        checkIfStaked={stakeName}
                        key={id}
                        listOfIds={listOfIds}
                        setListOfIds={setListOfIds}
                        lockCount={lockCount}
                        setLockCount={setLockCount}
                        checkRef={checkRef}
                        unstakedNFTId={unstakedNFTId}
                        isCheckboxActive={isCheckboxActive}
                        isSlectAllActive={isSlectAllActive}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  };

  const renderCardContainer = () => {
    return (
      <div className="card-container">
        {renderLockButton()}
        {renderCardsList()}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="main-cards-container">
        {renderSwitchTabs()}
        {renderCardContainer()}
      </div>
    );
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className="main-container">
      {!isApproved ? (
        <>
          <div
            className="approve"
            onClick={() => setApprovalForAll(reloadPage)}
          >
            <span> APPROVE NOW! </span>
          </div>
          <div className="approve-text">
            A ONE-TIME REQUEST TO ENJOY ALL FUNCTIONALITY
          </div>
        </>
      ) : loader ? (
        <>
          <div
            className="loader"
            style={{ marginTop: "18%", marginLeft: "45vw" }}
          ></div>
          <div
            style={{ color: "#ffffff", fontSize: "24px", fontWeight: "700", margin:"4%" }}
          >
            Interacting with Ethereum...
          </div>
        </>
      ) : (
        <>
           {/* <Sound
            url={tune}
            autoLoad = {true}
            playStatus = {Sound.status.PLAYING}
            loop={true}
          /> */}
          {renderHeader()}
          {renderBody()}
        </>
      )}
    </div>
  );
}
