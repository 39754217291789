import "./App.css";
import Dashboard from "./screens/Dashboard";
import Login from "./screens/login";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

const App = () => {

  const [walletAddress, setWalletAddress] = useState("");

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            exact
            path={"/"}
            render={() => {
              return !walletAddress ? (
                <Login
                  setWalletAddress={setWalletAddress}
                />
              ) : (
                <Redirect to="/Dashboard" />
              );
            }}
          />
          <Route
            exact
            path={"/Dashboard"}
            render={() => {
              return !walletAddress ? (
                <Redirect to="/" />
              ) : (
                <Dashboard />
              );
            }}
          />
        </Switch>
      </Router>
      <footer>
        <p className={"footer_text"}>Copyright, 2022 - PunkX NFT</p>
      </footer>
    </div>
  );
};

export default App;
