/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from "react";
import {
  stakeTokensCheck,
  unStakeTokens,
  lockTokens,
  getReward,
  claimRewards,
  isLocked
} from "../../contract/contract-utils";
import { getMetadata } from "../../utils";

import "./style.css";

export default function Card(profileData) {
  const {
    id,
    stakedActive,
    checkIfStaked,
    listOfIds,
    toggle,
    setListOfIds,
    lockCount,
    setLockCount,
    isCheckboxActive,
    isSlectAllActive,
    setAllSelected,
    checkRef,
    stakedNftId,
    unstakedNFTId,
    setLoader
  } = profileData;
  const [metaData, setMetaData] = useState({});

  // const [loader, setLoader] = useState(true);
  const [lock, setLock] = useState(false);
  const [reward, setReward] = useState(0);
  const [timer, setTimer] = useState(0)

  useEffect(async () => {
    const res = await getMetadata(id);
    setMetaData(res.data);
    let timerRes = await isLocked(id)
    
    setLock(timerRes[0])

    if(timerRes[0]){
    setTimer(timerRes[1])
    }
    try {
      if (checkIfStaked === "staked") {
        setReward(await getReward(id));
      }
    } catch {
      console.warn("error");
    }
    setLoader(false);
  }, []);

  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) ref.current.checked = isSlectAllActive;
  }, [isSlectAllActive, toggle]);

  const handleClick = (e) => {
    if (ref.current.checked) {
      setAllSelected(true)
      let idList = listOfIds;
      let count = lockCount;
      idList.push(e.target.value);
      if (stakedActive && idList.length === stakedNftId.length) {
        checkRef.current.checked = true;
      } else if (idList.length === unstakedNFTId.length) {
        checkRef.current.checked = true;
      }
      setListOfIds(listOfIds);
      count = count + 1;
      setLockCount(count);
    }

    if (!ref.current.checked) {
      setAllSelected(false);
      checkRef.current.checked = false;
      let idList = listOfIds;
      let count = lockCount;
      let result = idList.filter((value) => {
        if (parseInt(e.target.value) !== parseInt(value)) {
          return value;
        }
      });
      count = count - 1
      setListOfIds(result);
      setLockCount(count);
    }
  };

  const enums = {
    STAKE: "STAKE",
    UNSTAKE: "UNSTAKE",
  };

  return (
    <>
      <div className={lock ? "each-card-details-locked" : "each-card-details"}>
        <div className="check-box">
          {isCheckboxActive && (
            <input
              disabled={lock}
              type="checkbox"
              ref={ref}
              name={`PunkX #${id}`}
              value={id}
              onChange={handleClick}
              style={{ height: "20px", width: "20px", cursor: "pointer" }}
            />
          )}
        </div>
        <div>
          <img
            style={{ objectFit: "contain", height: "280px", width: "280px" }}
            src={metaData.image_url}
            alt="profile"
          />
        </div>
        <div className="display-name">{`PunkX #${id}`}</div>
        <a className="link" href={metaData.permalink} target="_blank">
          View On OpenSea
        </a>
        {checkIfStaked === "staked" ? (
          <div className="display-price" onClick={() => claimRewards([id], setLoader)}>
            CLAIM $DEGEN {reward.toFixed(2)}
          </div>
        ) : (
          ""
        )}
        {checkIfStaked === "staked" ? (
          <div
            className={
              isCheckboxActive || lock ? "unstake-button-disabled" : "unstake-button"
            }
            onClick={() => (!isCheckboxActive && unStakeTokens([id], setLoader))}
          >
            <span>UNSTAKE</span>
          </div>
        ) : (
          ""
        )}
        {checkIfStaked === "staked" ? (
          ""
        ) : (
          <div
            className={
              isCheckboxActive || lock? "unstake-button-disabled" : "unstake-button"
            }
            onClick={() =>
              !isCheckboxActive && stakeTokensCheck([id], false, setLoader)
            }
          >
            <span>STAKE</span>
          </div>
        )}
        {lock && <div style = {{paddingTop:"4px"}}>Time left : {timer} days</div>}
      </div>
    </>
  );
}
