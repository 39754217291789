import {ethers} from 'ethers'
import nft_abi from './nft-abi.json'
import token_abi from './token-abi.json'

let nftContract;
let tokenContract;
let address;
let nftContractAddress;
let tokenContractAddress;
export const providerHandler = async () => {

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const account = await provider.listAccounts();
    address = account[0];
    const signer = provider.getSigner();
  
     nftContractAddress = "0x82d9A362621784f34dc281B44B5cC82Ce8c3F07F";
     tokenContractAddress = "0x7DE080d43e64dCBeFb99040eEf03bC5ACf77853E";

    const nftAbi = nft_abi
    const tokenAbi = token_abi
      
    nftContract = new ethers.Contract(nftContractAddress, nftAbi, signer);
    tokenContract = new ethers.Contract(tokenContractAddress, tokenAbi, signer);
    console.log('contracts init done')
  };

export const get_address = async () => {
  return address;
}

export const unStackednftCount = async () => {
  const n = await nftContract.balanceOf(address);
  return n.toNumber();
}

export const getNFTid = async (index) => {
  const n = await nftContract.tokenOfOwnerByIndex(address, index);
  return n.toNumber();
}

export const isApprovedForAll = async () => {
  const n = await nftContract.isApprovedForAll(address,tokenContractAddress);
  return n;
}

export const getApproved = async (index) => {
  const n = await nftContract.getApproved(index)
  return n
}

export const setApprovalForAll = async (reloadPage) => {
  const n = await nftContract.setApprovalForAll(tokenContractAddress, true)
  reloadPage()
  return n;
}

export const balanceOf = async () => {
  const n = await tokenContract.balanceOf(address);
  console.log(n)
  return Number(ethers.utils.formatEther(n));
};

export const stakeTokens = async (indexes, lock, setLoader) => {
  console.log("ONSTAKE TOKEN")
  try{
    const n = await tokenContract.stakeTokens(indexes,lock);
    await n.wait()
    setLoader(false)
    return n;
  }catch(error){
    setLoader(false)
  }
}

export const unStakeTokens = async (indexes, setLoader) => {
  try{
    setLoader(true)
    const n = await tokenContract.unstakeTokens(indexes)
    await n.wait()
    setLoader(false)
    return n
  }catch(error){
    setLoader(false)
  }

}

export const lockTokens = async (indexes, setLoader) => {
  try{
    setLoader(true)
    const n = await tokenContract.lockTokens(indexes)
    await n.wait()
    setLoader(false)
    return n
  }catch(error){
    setLoader(false)
  }
}

export const stakedTokens = async (index) => {
  const n = await tokenContract.stakedTokens(index)
  return n
}

export const isLocked = async (index) => {
  const n =await stakedTokens(index)
  const locktime = n['lockTime'].toNumber();
  let delta = Date.now() - locktime*1000 
  let resResult = locktime*1000 +  (10*24*60*60000 - Date.now())
  if(delta < 10*24*60*60*1000){
    resResult = resResult/60000
    let numberOfMinutes = Math.ceil(resResult)
    let numberOfDays = Math.ceil(numberOfMinutes/1440)
    console.log(numberOfDays, "In function")
    return [true, numberOfDays];
  }
  else{
    resResult = resResult/60000
    return [false, Math.ceil(resResult)];
  }
}

export const getStakedUserTokens = async () => {
  const n = await tokenContract.getStakedUserTokens(address)
  let l =[]
  for (let i = 0; i < n.length; i++){
    l.push(n[i].toNumber())
  }
  return l
}

export const getReward = async (id) => {
  const n = await tokenContract.getReward(id)
  return Number(ethers.utils.formatEther(n));
}

export const claimRewards = async (ids, setLoader) => {
  try{
    setLoader(true)
    const n = await tokenContract.claimRewards(ids)
    await n.wait()
    setLoader(false)
    return n
  }catch(error){
    setLoader(false)
  }
}

export const stakeTokensCheck = async (indexs,lock, setLoader) => {
  setLoader(true)
  if(! await isApprovedForAll()){
    await setApprovalForAll()
  }
  await stakeTokens(indexs,lock,setLoader)
}

export const rewardFromMint = async (indexs) => {
  const n = await tokenContract.rewardFromMint(indexs)
    return n;

}

export const mintRewardClaimed = async (indexes) => {
  console.log("YES...")
  let unClaimed = []
  for (let i = 0; i <indexes.length; i++){
  if(indexes[i]<2222){ 
      let n = await tokenContract.mintRewardClaimed(indexes[i])
      if(!n) {
        unClaimed.push(indexes[i])
      }
    }
  console.log(unClaimed)
  }
  if(unClaimed.length === 0){
    return false
  }
  else{
    return unClaimed
  }
}

export const isBirthdayStart = async () => {
  const n = await tokenContract.punkXStart()
  const startTime = n.toNumber()
  const delta = Date.now() - startTime*1000
  if (delta < 10080*60000){
    return false;
  }
  else{
    return true;
  }
}