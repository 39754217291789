import React from "react";
import { get_address, providerHandler } from "../../contract/contract-utils";

import "./style.css";

export default function Login({ setWalletAddress }) {
  const connectWalletHandler = async () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(async (result) => {
          await providerHandler();
          get_address().then((e) => {
            setWalletAddress(e);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("login error");
      /* Add a toast here to install metamask*/
    }
  };

  const renderLoginModal = () => {
    return (
      <div className="login-modal">
        <div className="ui-logo">
          <img
            style={{ height: "92px", width: "246px", paddingTop: "10%" }}
            draggable={false}
            src={require("../../assets/game-logo.png")}
            alt="Logo"
          />
        </div>
        <div
          className="wallet-connection"
          onClick={() => {
            connectWalletHandler();
          }}
        >
          Connect Your Wallet
        </div>
      </div>
    );
  };

  return <div className="loginPage">{renderLoginModal()}</div>;
}
